// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apk-js": () => import("./../../../src/pages/apk.js" /* webpackChunkName: "component---src-pages-apk-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-megabox-hd-for-pc-js": () => import("./../../../src/pages/megabox-hd-for-pc.js" /* webpackChunkName: "component---src-pages-megabox-hd-for-pc-js" */),
  "component---src-pages-megabox-hd-ios-js": () => import("./../../../src/pages/megabox-hd-ios.js" /* webpackChunkName: "component---src-pages-megabox-hd-ios-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

